import "leaflet";
import "leaflet.markercluster";
import "lodash";

///////
// Worldmap-stuff
const markerIcon = L.icon({
  // size of the icon
  iconSize: [81, 81],

  // point of the icon which will correspond to marker's location
  iconAnchor: [40, 40],
  iconUrl: "/marker.png",
});

let map;
let markercluster;
export const renderMap = (params, poiSelectedCallback, mapMoveCallback, mapExternalPlacesClicked) => {
  if (!document.getElementById("mapid")) {
    return
  }

  if (!document.getElementById("mapid")._leaflet_id) {
    initMap(params, mapMoveCallback, mapExternalPlacesClicked)
  }
  placeMapMarkers(params.pois, poiSelectedCallback);
};

const initMap = (params, mapMoveCallback, mapExternalPlacesClicked) => {
  map = L.map("mapid", {
    zoomControl: false,
    maxZoom: 16,
    attributionControl: false
  });

  const imageUrl = '/map.svg'
  const imageBounds = [[55.208367, 7.600991], [53.223498, 11.931836]];

  const img = L.imageOverlay(imageUrl, imageBounds).addTo(map);

  if (params.zoom == 0) {
    map.fitBounds(imageBounds);

    const targetRatio = 1.4

    let zoomChange = 0
    // Safari-Problems:
    // ```javascript
    // console.log(img['_image']); -> A real object with attributes
    // console.log(img._image); -> undefined
    // ```
    const rect = img['_image'].getBoundingClientRect();
    while (Math.max(map._size.x / rect.width, map._size.y / rect.height) / Math.pow(2, zoomChange) > targetRatio) {
      zoomChange++;
    }

    zoomChange--;

    map.setZoom(map.getZoom() + zoomChange, { animate: false });
  } else {
    map.setView(params.coordinates, params.zoom);
  }

  map.on("moveend", () =>
    mapMoveCallback({ center: map.getCenter(), zoom: map.getZoom() })
  );

  map.createPane('currentposition');

  L.control
    .zoom({
      position: "topright"
    })
    .addTo(map);


  if (process.env.NPM_ENV !== 'stele') {
    const CustomControl = L.Control.extend({
      options: {
        position: 'topright'
      },

      onAdd: function (map) {
        const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control');

        const button = L.DomUtil.create('a', 'leaflet-control-external-places', container);
        button.setAttribute('role', 'button');
        button.setAttribute('aria-label', 'Show external places');

        L.DomEvent.on(button, 'click', function () {
          mapExternalPlacesClicked()
        });

        return container;
      }
    });

    const customControl = new CustomControl();
    customControl.addTo(map);
  }
}

const markerClusterIcon = cluster => {
  return L.divIcon({
    className: "map-marker-cluster",
    html: cluster.getChildCount(),
    iconSize: [81, 81]
  });
};

export const placeMapMarkers = (pois = [], poiSelectedCallback) => {
  if (markercluster) {
    map.removeLayer(markercluster);
  }
  markercluster = L.markerClusterGroup({
    iconCreateFunction: markerClusterIcon
  }
  );
  pois.forEach(poi => {
    var marker = L.marker([poi.latitude, poi.longitude], {
      icon: markerIcon
    });

    var content = L.DomUtil.create("div")

    if (process.env.NPM_ENV === 'stele') {
      content.innerHTML = `<h1>${poi.year}</h1><div class="name">${poi.name}</div>`
    } else {
      content.innerHTML = `<h1>${poi.year}</h1><div class="name">${poi.name}</div><a href="#" onclick="openNavigation({latitude: ${poi.latitude}, longitude: ${poi.longitude}}); return false;">Route berechnen</a>`
    }

    var popup = L.popup({ minWidth: 127, maxWidth: 127 }).setContent(content)
    L.DomEvent.on(content, "click", () => poiSelectedCallback(poi.id))
    marker.bindPopup(popup)

    markercluster.addLayer(marker);
  });
  map.addLayer(markercluster);
};
