export const saveToStorage = (params) => {
    if (storageAvailable("localStorage") && localStorage.getItem("dataProtectionAccepted")) {
        const record = JSON.stringify({ timestamp: Date.now(), data: params.value });
        try {
            localStorage.setItem(params.key, record);
        } catch (e) {
            if (isQuotaExceededException(e, "localStorage")) {
                pruneLocalStorage();
                localStorage.setItem(params.key, record);
            } else {
                throw e;
            }
        }
    }
}

export const readFromStorage = (key, callback) => {
    const record = (storageAvailable("localStorage") && JSON.parse(localStorage.getItem(key))) || {};
    if (record.data) {
        callback({ key: key, value: record.data })
    } else {
        callback({ error: `Key "${key}" not found in cache` });
    }
}

const pruneLocalStorage = () => {
    const faction = 1 / 4;
    var records = [];
    // read all records
    for (var i = 0; i < localStorage.length; i++) {
        var key = localStorage.key(i);
        if (key.startsWith("poi-")) {
            var record = JSON.parse(localStorage.getItem(key));
            records.push({ key: key, timestamp: record.timestamp });
        }
    }
    // sort oldest first
    records.sort((record1, record2) => {
        return record1.timestamp > record2.timestamp;
    });
    // remove oldest faction of records
    for (var i = 0; i < records.length * faction; i++) {
        localStorage.removeItem(records[i].key);
    }
}

// copied from https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
const storageAvailable = (type) => {
    var storage;
    try {
        storage = window[type];
        var x = "__storage_test__";
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch (e) {
        return isQuotaExceededException(e);
    }
}

const isQuotaExceededException = (e) => {
    return e instanceof DOMException && (
        // everything except Firefox
        e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
        // acknowledge QuotaExceededError only if there's something already stored
        (storage && storage.length !== 0);
}
