module Main exposing (Model, main)

import Api
import Api.Proxy as Proxy
import Browser exposing (Document)
import Browser.Navigation as Nav
import Flash
import Json.Encode as E
import Page
import Page.Blank as Blank
import Page.Intro as Intro
import Page.NotFound as NotFound
import Page.Poi.List as PoiList
import Page.Poi.Map as Map
import Page.Poi.Show as PoiShow
import Page.StaticPages.Show as StaticPagesShow
import Ports
import RemoteData exposing (RemoteData(..))
import Route exposing (Route)
import Session exposing (Session)
import Time
import Url exposing (Url)



---- MODEL ----


type Model
    = Show PageModel
    | Loading PageModel (Proxy.ProxyData PageModel)


type PageModel
    = Map Map.Model
    | PoiList PoiList.Model
    | PoiShow PoiShow.Model
    | StaticPagesShow StaticPagesShow.Model
    | NotFound Session
    | Intro Intro.Model
      -- A state that shows an empty Page during URL-Changes
    | Redirect Session


init : ( String, Bool, Bool ) -> Url -> Nav.Key -> ( Model, Cmd Msg )
init ( dataProtectionStatus, nativeShareAvailable, stele ) url navKey =
    let
        ( model, cmdChangeRoute ) =
            changeRouteTo (Route.fromUrl url)
                (Redirect
                    (Session.build navKey
                        (Session.stringToDataProtectionStatus dataProtectionStatus)
                        nativeShareAvailable
                        stele
                    )
                )
    in
    ( model, cmdChangeRoute )


changeRouteTo : Maybe Route -> PageModel -> ( Model, Cmd Msg )
changeRouteTo maybeRoute pageModel =
    let
        session =
            toSession pageModel
    in
    case ( maybeRoute, pageModel ) of
        ( Nothing, _ ) ->
            ( Show <| NotFound session, Cmd.none )

        -- Simple Pages
        ( Just Route.Map, Map _ ) ->
            -- Never reinit Map, while it's already shown
            ( Show pageModel, scrollToAnchor )

        ( Just Route.Map, _ ) ->
            handleProxyStatus pageModel
                (Map.init session |> Proxy.map Map |> scrollToAnchorOnFinish)

        ( Just Route.PoiList, PoiList _ ) ->
            ( Show pageModel, scrollToAnchor )

        ( Just Route.PoiList, _ ) ->
            handleProxyStatus pageModel
                (PoiList.init session |> Proxy.map PoiList |> scrollToAnchorOnFinish)

        -- Complex Pages
        ( Just (Route.PoiShow id poiTab previewToken), PoiShow oldModel ) ->
            if PoiShow.equalRoute { id = id, poiTab = poiTab, previewToken = previewToken } oldModel then
                ( Show pageModel, scrollToAnchor )

            else
                handleProxyStatus pageModel
                    (PoiShow.init session id poiTab previewToken |> Proxy.map PoiShow |> scrollToAnchorOnFinish)

        ( Just (Route.PoiShow id poiTab previewToken), _ ) ->
            handleProxyStatus pageModel
                (PoiShow.init session id poiTab previewToken |> Proxy.map PoiShow |> scrollToAnchorOnFinish)

        ( Just (Route.StaticPagesShow slug previewToken), StaticPagesShow oldModel ) ->
            if StaticPagesShow.equalRoute { slug = slug, previewToken = previewToken } oldModel then
                ( Show pageModel, scrollToAnchor )

            else
                handleProxyStatus pageModel
                    (StaticPagesShow.init session slug previewToken |> Proxy.map StaticPagesShow |> scrollToAnchorOnFinish)

        ( Just (Route.StaticPagesShow slug previewToken), _ ) ->
            handleProxyStatus pageModel
                (StaticPagesShow.init session slug previewToken |> Proxy.map StaticPagesShow |> scrollToAnchorOnFinish)

        ( Just Route.Intro, _ ) ->
            handleProxyStatus pageModel
                (Intro.init session |> Proxy.map Intro |> scrollToAnchorOnFinish)

        ( Just Route.Reload, _ ) ->
            ( Show pageModel
            , Cmd.batch
                [ Route.replaceUrl (.navKey <| toSession pageModel) Route.PoiList
                ]
            )



---- UPDATE ----


type Msg
    = Ignored
    | ChangedUrl Url
    | ClickedLink Browser.UrlRequest
    | GotProxyMsg Proxy.Msg
    | PageMsg PageMsg
    | GotSessionMsg Session.Msg
    | MapViewportChanged E.Value


type PageMsg
    = GotMapMsg Map.Msg
    | GotPoiListMsg PoiList.Msg
    | GotPoiShowMsg PoiShow.Msg
    | GotStaticPagesShowMsg StaticPagesShow.Msg
    | GotIntroMsg Intro.Msg


updateSession : Session.Msg -> PageModel -> ( PageModel, Cmd Msg )
updateSession msg page =
    let
        ( session, subMsg ) =
            Session.update msg (toSession page)

        updatedPage =
            replaceSession page session
    in
    ( updatedPage, Cmd.map GotSessionMsg subMsg )


replaceSession : PageModel -> Session -> PageModel
replaceSession page session =
    case page of
        PoiList subModel ->
            PoiList { subModel | session = session }

        PoiShow subModel ->
            PoiShow { subModel | session = session }

        StaticPagesShow subModel ->
            StaticPagesShow { subModel | session = session }

        Map subModel ->
            Map { subModel | session = session }

        Intro subModel ->
            Intro { subModel | session = session }

        NotFound _ ->
            NotFound session

        Redirect _ ->
            Redirect session


toSession : PageModel -> Session
toSession page =
    case page of
        PoiList model ->
            model.session

        PoiShow model ->
            model.session

        StaticPagesShow model ->
            model.session

        Map model ->
            model.session

        NotFound session ->
            session

        Intro model ->
            model.session

        Redirect session ->
            session


toPageModel : Model -> PageModel
toPageModel model =
    case model of
        Show pageModel ->
            pageModel

        Loading pageModel _ ->
            pageModel


replacePageModel : Model -> PageModel -> Model
replacePageModel model newPageModel =
    case model of
        Show _ ->
            Show newPageModel

        Loading _ proxyStatus ->
            Loading newPageModel proxyStatus


hideSidebar : PageModel -> PageModel
hideSidebar pageModel =
    let
        session =
            toSession pageModel
    in
    replaceSession pageModel { session | sidebarVisible = False }


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case ( msg, model ) of
        ( ChangedUrl url, _ ) ->
            changeRouteTo (Route.fromUrl url) (hideSidebar (toPageModel model))

        ( ClickedLink urlRequest, _ ) ->
            case urlRequest of
                Browser.Internal url ->
                    ( model
                    , Nav.pushUrl (model |> toPageModel |> toSession |> .navKey) (Url.toString url)
                    )

                Browser.External href ->
                    ( model, Nav.load href )

        ( GotProxyMsg subMsg, Loading pageModel proxyData ) ->
            handleProxyStatus
                pageModel
                (Proxy.update subMsg proxyData)

        ( GotProxyMsg _, Show _ ) ->
            ( model, Cmd.none )

        ( GotSessionMsg subMsg, _ ) ->
            let
                ( newPageModel, cmd ) =
                    updateSession subMsg <| toPageModel model
            in
            ( replacePageModel model newPageModel, cmd )

        ( PageMsg subMsg, _ ) ->
            let
                ( newModel, cmd ) =
                    updatePage subMsg model
            in
            case newModel of
                Show newPageModel ->
                    ( replacePageModel model newPageModel, cmd )

                Loading _ _ ->
                    ( newModel, cmd )

        ( MapViewportChanged json, _ ) ->
            let
                newSession =
                    Session.setMapViewport json (model |> toPageModel |> toSession)

                newPageModel =
                    replaceSession (toPageModel model) newSession
            in
            ( replacePageModel model newPageModel, Cmd.none )

        ( Ignored, _ ) ->
            ( model, Cmd.none )


updatePage : PageMsg -> Model -> ( Model, Cmd Msg )
updatePage msg model =
    case ( msg, toPageModel model ) of
        ( GotMapMsg subMsg, Map subModel ) ->
            Map.update subMsg subModel
                |> updateWith Map GotMapMsg (toPageModel model)

        ( GotMapMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotPoiListMsg subMsg, PoiList subModel ) ->
            PoiList.update subMsg subModel
                |> updateWith PoiList GotPoiListMsg (toPageModel model)

        ( GotPoiListMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotPoiShowMsg subMsg, PoiShow subModel ) ->
            PoiShow.update subMsg subModel
                |> updateWith PoiShow GotPoiShowMsg (toPageModel model)

        ( GotPoiShowMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotStaticPagesShowMsg subMsg, StaticPagesShow subModel ) ->
            StaticPagesShow.update subMsg subModel
                |> updateWith StaticPagesShow GotStaticPagesShowMsg (toPageModel model)

        ( GotStaticPagesShowMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotIntroMsg subMsg, Intro subModel ) ->
            Intro.update subMsg subModel
                |> updateWith Intro GotIntroMsg (toPageModel model)

        ( GotIntroMsg _, _ ) ->
            ( model, Cmd.none )


handleProxyStatus : PageModel -> Proxy.ProxyStatus PageModel -> ( Model, Cmd Msg )
handleProxyStatus pageModel proxyStatus =
    case proxyStatus of
        Proxy.Loading proxyData loadCmd ->
            ( Loading pageModel proxyData
            , Cmd.map GotProxyMsg loadCmd
            )

        Proxy.Finished result finishCmd ->
            ( Show <| result
            , case result of
                Map subModel ->
                    Cmd.batch
                        [ Map.renderMap subModel
                        , Cmd.map (always Ignored) finishCmd
                        ]

                _ ->
                    Cmd.map (always Ignored) finishCmd
            )

        Proxy.Error err ->
            ( Show <| pageModel
            , Cmd.map GotSessionMsg <| Session.addFlash <| Flash.error <| Api.errorToString err
            )



---- VIEW ----


view : Model -> Document Msg
view model =
    case model of
        Show pageModel ->
            viewPage pageModel False

        Loading pageModel proxyData ->
            viewPage pageModel proxyData.loadingSlowly


viewPage : PageModel -> Bool -> Document Msg
viewPage model _ =
    let
        doViewPage page toMsg config =
            Page.view
                (toSession model)
                toMsg
                GotSessionMsg
                Ignored
                page
                config
    in
    case model of
        {-
           Boring Routes
        -}
        Redirect _ ->
            doViewPage Page.Other (\_ -> Ignored) Blank.view

        NotFound _ ->
            doViewPage Page.Other (\_ -> Ignored) NotFound.view

        {-
           Interesting Routes
        -}
        Map subModel ->
            doViewPage Page.Map (PageMsg << GotMapMsg) (Map.view subModel)

        PoiList subModel ->
            doViewPage Page.PoiList (PageMsg << GotPoiListMsg) (PoiList.view subModel)

        PoiShow subModel ->
            doViewPage Page.PoiShow (PageMsg << GotPoiShowMsg) (PoiShow.view subModel)

        StaticPagesShow subModel ->
            doViewPage Page.StaticPagesShow (PageMsg << GotStaticPagesShowMsg) (StaticPagesShow.view subModel)

        Intro subModel ->
            doViewPage Page.Intro (PageMsg << GotIntroMsg) (Intro.view subModel)


scrollToAnchor : Cmd Msg
scrollToAnchor =
    Ports.scrollToAnchor ()


scrollToAnchorOnFinish : Proxy.ProxyStatus a -> Proxy.ProxyStatus a
scrollToAnchorOnFinish proxyStatus =
    Proxy.succeedWithCmd identity scrollToAnchor
        |> Proxy.include proxyStatus



---- PROGRAM ----


main : Program ( String, Bool, Bool ) Model Msg
main =
    Browser.application
        { init = init
        , onUrlChange = ChangedUrl
        , onUrlRequest = ClickedLink
        , subscriptions = subscriptions
        , update = update
        , view = view
        }


updateWith :
    (subModel -> PageModel)
    -> (subMsg -> PageMsg)
    -> PageModel
    -> ( Proxy.ProxyStatus subModel, Cmd subMsg, Cmd Session.Msg )
    -> ( Model, Cmd Msg )
updateWith toModel toMsg currentPageModel ( subModelProxy, subCmd, cmd ) =
    let
        ( model, proxyCmd ) =
            handleProxyStatus currentPageModel (subModelProxy |> Proxy.map toModel)
    in
    ( model
    , Cmd.batch [ Cmd.map (PageMsg << toMsg) subCmd, Cmd.map GotSessionMsg cmd, proxyCmd ]
    )



-- SUBSCRIPTIONS


subscriptions : Model -> Sub Msg
subscriptions _ =
    Sub.batch
        [ Ports.mapPoiSelected Map.mapPoiSelected |> Sub.map (PageMsg << GotMapMsg)
        , Ports.mapViewportChanged MapViewportChanged
        , Ports.mapExternalPlacesClicked (\_ -> Session.showExternalPlaces) |> Sub.map GotSessionMsg
        , Ports.nativeShareFailed (\_ -> Session.showFallbackShareBar) |> Sub.map GotSessionMsg
        , Ports.loadProxyData Proxy.loadedCache |> Sub.map GotProxyMsg
        , Time.every 1000 (PageMsg << GotPoiShowMsg << PoiShow.SlideshowTick)
        ]
