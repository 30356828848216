// Create a class for the element
class AudioPlayer extends HTMLElement {

  constructor() {
    super();

    this.onLoadedMetaData = this.onLoadedMetaData.bind(this);
    this.onTimeUpdate = this.onTimeUpdate.bind(this);
    this.renderPlayButton = this.renderPlayButton.bind(this);
    this.renderPauseButton = this.renderPauseButton.bind(this);
    this.renderStyle = this.renderStyle.bind(this);
    this.renderClock = this.renderClock.bind(this);
    this.updateTimeline = this.updateTimeline.bind(this);
    this.changeTime = this.changeTime.bind(this);
  }
  connectedCallback() {
    const root = document.createElement('div')

    this.appendChild(root);

    const title = this.getAttribute("title");
    const audioPlayerId = this.getAttribute("audioPlayerId");
    const convertedUrl = this.getAttribute("convertedUrl");
    const originalUrl = this.getAttribute("originalUrl");
    const originalMime = this.getAttribute("originalMime");

    root.innerHTML = `
      <div class="audio-player-wrapper">
        <style></style>
        <audio id="${audioPlayerId}" class="w-100" title="${title}" preload="metadata">
          <source src="${convertedUrl}" type="audio/mpeg" />
          <source src="${originalUrl}" type="${originalMime}" />
          Dieser Browser unterstützt kein &lt;audio&gt;
        </audio>
        <div class="audio-player-controls">
          <div class="audio-player-controls-playpause">
          </div>
          <div class="audio-player-controls-center">
            <div class="audio-player-controls-title">${title}</div>
            <input class="audio-player-controls-timeline" max="0" name="timeline" step="0.1" type="range" value="0" aria-label="Fortschrittsbalken"/>
          </div>
          <div class="audio-player-controls-clock">
          </div>
        </div>
      </div>
    `;

    const audio = root.querySelector("audio");

    audio.addEventListener("loadedmetadata", this.onLoadedMetaData);
    audio.addEventListener("timeupdate", this.onTimeUpdate);
    audio.addEventListener("pause", this.renderPlayButton);
    audio.addEventListener("play", this.renderPauseButton);
    audio.addEventListener("ended", this.renderPlayButton);

    const timeline = root.querySelector(".audio-player-controls-timeline");

    timeline.addEventListener("input", this.changeTime);

    this.renderPlayButton();
  }

  onLoadedMetaData(event) {
    const duration = event.target.duration;
    this.renderClock(duration);
    this.onTimeUpdate(event);
  }

  onTimeUpdate(event) {
    const duration = event.target.duration;
    const currentTime = event.target.currentTime;
    const progress = currentTime / duration * 100;
    this.updateTimeline(currentTime, duration);
    this.renderStyle(progress);
  }

  renderPlayButton() {
    const container = this.querySelector(".audio-player-controls-playpause");
    const audio = this.querySelector("audio");
    container.innerHTML = `<button aria-label="Abspielen"><img src="/play.png" width="21" height="30" alt="Abspielen-Symbol" type="image/png" /></button>`;
    container.firstChild.addEventListener("click", () => audio.play());
  }

  renderPauseButton() {
    const container = this.querySelector(".audio-player-controls-playpause");
    const audio = this.querySelector("audio");
    container.innerHTML = `<button aria-label="Pause"><img src="/pause.png" height="31" width="25" alt="Pause-Symbol" type="image/png" /></button>`;
    container.firstChild.addEventListener("click", () => audio.pause());
  }

  renderStyle(progress) {
    const color = this.getAttribute("color");
    const audioPlayerId = this.getAttribute("audioPlayerId");

    const style = this.querySelector("style");

    style.innerText = `#${audioPlayerId} ~ div input[type=range]::-webkit-slider-runnable-track{background:-webkit-linear-gradient(left, ${color} 0%, ${color} ${progress}%, #A3A3A3 ${progress}%);}#${audioPlayerId} ~ div input[type=range]::-webkit-slider-thumb{border:2px solid ${color};}#${audioPlayerId} ~ div input[type=range]::-moz-range-track{background:-webkit-linear-gradient(left, ${color} 0%, ${color} ${progress}%, #A3A3A3 ${progress}%);}#${audioPlayerId} ~ div input[type=range]::-moz-range-thumb{border:2px solid ${color};}#${audioPlayerId} ~ div input[type=range]::-ms-fill-lower{border:${color};}#${audioPlayerId} ~ div input[type=range]:focus::-ms-fill-lower{border:${color};}#${audioPlayerId} ~ div input[type=range]::-ms-thumb{border:2px solid ${color};}`
  }

  renderClock(duration) {
    const hours = Math.floor(duration / 3600).toString().padStart(2, "0");
    const minutes = Math.floor((duration % 3600) / 60).toString().padStart(2, "0");
    const seconds = Math.floor(duration % 60).toString().padStart(2, "0");

    const clock = this.querySelector(".audio-player-controls-clock");

    if (hours == "00") {
      clock.innerText = `${minutes}:${seconds}`
    } else {
      clock.innerText = `${hours}:${minutes}:${seconds}`
    }
  }

  updateTimeline(currentTime, duration) {
    const timeline = this.querySelector(".audio-player-controls-timeline");
    timeline.setAttribute("max", duration);
    timeline.value = currentTime;
  }

  changeTime(event) {
    const audio = this.querySelector("audio");
    audio.currentTime = event.target.value;
    event.preventDefault();
  }
}

// Define the new element
customElements.define('audio-player', AudioPlayer);
