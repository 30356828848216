import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css"
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import "./main.scss";

import { Elm } from "./Main.elm";

import * as map from "./map";
import * as cache from "./cache";
import './audioPlayer';

const dataProtectionAccepted = localStorage.getItem('dataProtectionAccepted') == 'true' ? 'Accepted' : 'Unset';
const stele = process.env.NPM_ENV === 'stele'

const app = Elm.Main.init({
  node: document.getElementById("root"),
  flags: [dataProtectionAccepted, navigator.share !== undefined, stele]
});


app.ports.openNativeSharing.subscribe(async url => {
  try {
    const title = "Lerne Orte der Demokratie in SH kennen"
    const text = "Dass wir in einer Demokratie leben, ist nicht selbstverständlich. Immer wieder wurde und wird auch in Schleswig-Holstein um demokratische Rechte gerungen. Viele Orte stehen beispielhaft für diese wichtigen Auseinandersetzungen. Wir stellen sie vor – in einer multimedialen und interaktiven App."

    await navigator.share({ title, text, url })
  } catch (e) {
    app.ports.nativeShareFailed.send()
  }
})

app.ports.renderMap.subscribe(params => {
  requestAnimationFrame(() =>
    map.renderMap(params,
      app.ports.mapPoiSelected.send,
      app.ports.mapViewportChanged.send,
      app.ports.mapExternalPlacesClicked.send
    )
  );
})

app.ports.openNavigation.subscribe(params => {
  window.openNavigation(params)
})

app.ports.setDataProtectionStatus.subscribe((status) => {
  if (status == 'Accepted') localStorage.setItem('dataProtectionAccepted', true);
  else localStorage.removeItem('dataProtectionAccepted');
})

app.ports.scrollToAnchor.subscribe(() => {
  requestAnimationFrame(() => {
    const hash = window.location.hash
    if (hash) {
      const hashWithoutHash = hash.substr(1)
      const target = window.document.querySelector(`[id="${hashWithoutHash}"]`)
      if (target) {
        target.scrollIntoView()
      }
    } else {
      document.body.scrollTop = 0 //safari
      document.documentElement.scrollTop = 0 //others
    }
  })
})



// instagram has to be rendered in background, because we can't easily tell if there is a real demand for it
// also it doesn't really slow down anything
const renderInstagram = () => {
  if (window.instgrm) {
    window.instgrm.Embeds.process();
  }
}

setInterval(renderInstagram, 1000);



window.openNavigation = (coords) => {
  if /* if we're on iOS, open in Apple Maps */
    ((navigator.platform.indexOf("iPhone") != -1) ||
    (navigator.platform.indexOf("iPad") != -1) ||
    (navigator.platform.indexOf("iPod") != -1))
    window.open("maps://maps.google.com/maps?daddr=" + coords.latitude + "," + coords.longitude + "&amp;ll=");
  else /* else use Google */
    window.open("https://maps.google.com/maps?daddr=" + coords.latitude + "," + coords.longitude + "&amp;ll=");
}

// clicks the intro after 3 seconds
setTimeout(() => {
  const title = document.querySelector(".intro-overlay-content .title");
  if (title) {
    title.click();
  }
}, 3000);


// sets vh-variable because the default vh doesn't count in collapsible browser bars
function setVh() {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
};

window.addEventListener('resize', setVh);
window.addEventListener('orientationchange', setVh);

setVh();

// Cache

app.ports.saveToCache.subscribe((params) => {
  cache.saveToStorage(params)
})

app.ports.readFromCache.subscribe((key) => {
  cache.readFromStorage(key, app.ports.loadProxyData.send)
})


// adds handlers for focus styling with only the keyboard and not the mouse
// Let the document know when the mouse is being used
document.body.addEventListener('mousedown', function () {
  document.body.classList.add('using-mouse');
});

// Re-enable focus styling when Tab is pressed
document.body.addEventListener('keydown', function (event) {
  console.log(event, event.key)
  if (event.key === "Tab") {
    document.body.classList.remove('using-mouse');
  }
});

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register(new URL('serviceWorker.js', location.origin))
}
